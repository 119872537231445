<template>
<div>
    <div class="wrap">
        <form name="mfrm" @submit.prevent="getStatusList" method="post">
            <div class="con_top">
                <h2><span>■</span> 급여통계</h2>
            </div>
            <div class="con mt-30">
                <div class="con_table">
                    <div class="search2 mb-30">
                        <div class="float-left dp-block mt-5">
                            <select class="w-200px pl-05" v-model="idxCrmCenter" @change="getStatusList()">
                                <option value="">전체</option>
                                <option value="0">본원센터</option>
                                <option v-for="(item, index) of centerList" :key="index" :value="item.idx">{{item.name}}</option>
                            </select>
                        </div>

                        <input type="hidden" name="_token" id="_token" value="EqLJL0tVa23F5ikcDmzYVhVNw50lB9k5eDl840Bi">
                        <input type="hidden" name="this_month" id="this_month" value="2020-10">
                        <div>기간검색 :
                            <select name="birth-yyyy" v-model="startYY" class="ml-20 pl-05" id="birth-yyyy">
                                <option v-for="year in parseYear()" :key="year" :value="year">{{year}}년</option>
                            </select>
                            <select name="birth-mm" v-model="startMM" class="ml-10 pl-05" id="birth-mm">
                                <option v-for="index in 12" :key="index" :value="index">{{index}}월</option>
                            </select>
                            ~
                            <select name="birth-yyyy" v-model="endYY" class="ml-10 pl-05" id="birth-yyyy">
                                <option v-for="year in parseYear()" :key="year" :value="year">{{year}}년</option>
                            </select>
                            <select name="birth-mm" v-model="endMM" class="ml-10 pl-05" id="birth-mm">
                                <option v-for="index in 12" :key="index" :value="index">{{index}}월</option>
                            </select>
                            <br>
                        </div>
                    </div>
                    <div>
                        <button type="button" class="down-btn float-right mb-20" @click="excelDown()">엑셀파일 다운로드</button>
                        <table v-if="tabType === 'statistics'" class="default_table">
                            <tbody v-if="loading">
                                <tr >
                                    <th>협약센터명</th>
                                    <th>급여지급</th>
                                    <th>계약금액</th>
                                    <th>총 건수</th>
                                    <th>총액</th>
                                    <th>비고</th>
                                    <th>결산비고</th>
                                </tr>
                                <tr>
                                    <td colspan="8">검색중입니다.</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <th>협약센터명</th>
                                    <th>급여지급</th>
                                    <th>계약금액</th>
                                    <th>총 건수</th>
                                    <th>총액</th>
                                    <th>비고</th>
                                    <th>결산비고</th>
                                </tr>
                                <tr v-for="(item, index) of status" :key="index">
                                    <td>{{item.name}}</td>
                                    <td>{{item.salaryType}}</td>
                                    <td>{{item.consPrice}}원</td>
                                    <td>{{item.totReservation}}건</td>
                                    <td>{{item.totPrice}}원</td>
                                    <td class="red">{{item.etc1}}</td>
                                    <td class="left" colspan="3">
                                        <input type="text" v-model="item.etc2" class="ml-20 w-70per">
                                        <button type="button" class="default-btn ml-20" @click="checkName(item.idxCrmCenter,item.etc2)">입력</button>
                                    </td>
                                </tr>
                                <tr v-if="status.length === 0">
                                    <td colspan="8">검색 결과가 없습니다.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import FileSaver from 'file-saver'
export default {
    components: {

    },
    data: () => ({
        startDate: '', // 기간검색-시작날짜
        endDate: '', // 기간검색-종료날짜
        name: '',
        idxCrmCenter: '',
        idxCrmCompany: -1,
        centerList: [],
        companyList: [],
        startYY: 2020,
        startMM: 1,
        endYY: 2020,
        endMM: 1,

        tabType: 'statistics', // 탭 타입
        salaryMemo:'',
        etc2:'',
        status: [],
        loading:true,
    }),

    mounted() {
        let date = new Date()
        this.startYY = date.getFullYear();
        this.startMM = date.getMonth() + 1;
        this.endYY = date.getFullYear();
        this.endMM = date.getMonth() + 1;
        this.getCenterListAll();
        this.getCompanyListAll();
        this.getStatusList();
    },
    methods: {
        // 결산비고입력
        checkName(idxCrmCenter,etc2) {
            let params = {
                idxCrmCenter: idxCrmCenter,
                salaryMemo: etc2
            }
            this.axios.post('/api/v1/status/main/salaryMemo', params, {
                    // params: params  
                }).then(res => {
                    alert("입력 완료 되었습니다.")
                    console.log('HI', res)
                }).catch(err => {
                    console.log(err)
                    alert("개발팀에게 문의바랍니다.")
                })
        },
        // 컴마 넣기
        numberWithCommas(x) {
            if(x === undefined) {
                return 0
            } else {
                x = x + ''
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },

        // 현재 년도에 따라서 120년만큼 selectbox
        parseYear() {
            var nowYear = new Date().getFullYear()
            var parseYear = []
            for (var i = 0; i <= 120; i++) {
                parseYear.push(nowYear - i)
            }
            return parseYear
        },

        getCenterListAll() {

            this.axios.get('/api/v1/center/all', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.centerList = res.data.centerList
                    }
                })
                .catch(err => {
                    alert(err)
                    console.log(err)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 소속리스트(기관리스트)
        getCompanyListAll() {
            this.axios.get('/api/v1/company/all', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.companyList = res.data.companyList
                    } else {
                        //alert('소속리스트 결과가 없습니다.')
                    }
                })
                .catch(err => {
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        getStatusList() {

            let url = ""

            if (this.tabType === "statistics") {
                url = "/main/salaryStatistics"
            } else {
                url = "/main/route"
            }

            let tempMM = ""
            // 날짜 데이터 파싱
            if (this.startMM < 10) {
                tempMM = "0" + this.startMM
            } else {
                tempMM = this.startMM
            }
            let startDate = this.startYY.toString() + '-' + tempMM

            if (this.endMM < 10) {
                tempMM = "0" + this.endMM
            } else {
                tempMM = this.endMM
            }
            let endDate = this.endYY.toString() + '-' + tempMM

            let params = {
                name: this.name,
                idxCrmCompany: this.idxCrmCompany,
                idxCrmCenter: this.idxCrmCenter,
                startDate: startDate,
                endDate: endDate
            };

            this.loading = true;

            this.axios.get('/api/v1/status' + url, {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.status = res.data.status;
                    } else if (res.data.err === 403) {
                        alert('열람 권한이 없습니다.')
                        history.back()
                    } else {
                        this.status = [];
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
                .finally(()=>{
                    this.loading = false;
                });
        },

        excelDown() {

            //alert(this.tabType)

            let url = "";

            if (this.tabType === "statistics") {
                url = "/centerSalary-list"
            } else {
                url = "/status-main2"
            }

            // 날짜 데이터 파싱
            if (this.startMM < 10) {
                this.startMM = "0" + this.startMM
            }
            let startDate = this.startYY.toString() + '-' + this.startMM.toString()

            if (this.endMM < 10) {
                this.endMM = "0" + this.endMM
            }
            let endDate = this.endYY.toString() + '-' + this.endMM.toString()

            let params = {
                type: this.tabType,
                name: this.name,
                idxCrmCompany: this.idxCrmCompany,
                idxCrmCenter: this.idxCrmCenter,
                startDate: startDate,
                endDate: endDate
            }

            let headers = {
                //'Content-Disposition': "attachment; filename=" + fileName + ".xlsx",
                'Content-Type': 'ms-vnd/excel;charset=UTF-8'
            }

            this.axios.defaults.headers.post[headers]

            this.axios.get('/api/v1/excel' + url, {
                params: params,
                responseType: 'arraybuffer'
            }).then(res => {
                FileSaver.saveAs(new Blob([res.data]), "salaryStatistics_main_template.xlsx")
            }).catch(err => {
                console.log(err)
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            })

        },

    }
}
</script>
